"use client";
import React, { useState, useEffect, useCallback, useRef } from "react";
import _ from "lodash";
import airportDetails from "@/dataSet/airportDetails";
import { useRouter } from "next/navigation";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "@/redux/flightsSlice";
import { MdOutlineLocalAirport } from "react-icons/md";
import { FaCaretUp } from "react-icons/fa";
import globalAxiosURL from "@/hooks/globalAxiosURL";
 
const Page = ({
  fromRatingPage,
  travelerCounts,
  departureAirports,
  setDepartureAirports,
  arrivalAirports,
  setArrivalAirports,
  departureDates,
  setDepartureDates,
}) => {
  const dispatch = useDispatch();
  const [inputGroups, setInputGroups] = useState([{ id: 1 }]);
  const axiosURL = globalAxiosURL();
  // const [departureAirports, setDepartureAirports] = useState([
  //   { name: "", code: "" },
  //   { name: "", code: "" },
  // ]);
  // const [arrivalAirports, setArrivalAirports] = useState([
  //   { name: "", code: "" },
  //   { name: "", code: "" },
  // ]);
  // const [departureDates, setDepartureDates] = useState(["", ""]);
  const [dropdownData, setDropdownData] = useState({ from: [], to: [] });
  const [focus, setFocus] = useState({ from: null, to: null });
  const dropdownRef = useRef(null);
  const router = useRouter();
  const { agentData } = useSelector((state) => state.agentInfo);

  const customIndexList = [
    { code: "DAC", index: 1 },
    { code: "CGP", index: 2 },
    { code: "CXB", index: 3 },
    { code: "SPD", index: 4 },
    { code: "ZYL", index: 5 },
    { code: "RJH", index: 6 },
    { code: "BZL", index: 7 },
    { code: "DXB", index: 8 },
    { code: "JFK", index: 9 },
    { code: "SIN", index: 10 },
  ];

  const customIndexedAirports = customIndexList
    .map(({ code, index }) => {
      const airport = airportDetails.find((a) => a.airportCode === code);
      return airport ? { ...airport, customIndex: index } : null;
    })
    .filter((airport) => airport !== null);

  // Fetch data for dropdown list
  const debouncedFetchFromData = useCallback(
    _.debounce((value) => {
      filterData(value, "from");
    }, 300),
    []
  );

  const debouncedFetchToData = useCallback(
    _.debounce((value) => {
      filterData(value, "to");
    }, 300),
    []
  );

  const filterData = (value, type) => {
    if (!value) {
      setDropdownData((prevState) => ({
        ...prevState,
        [type]: customIndexedAirports,
      }));
      return;
    }

    const filteredList = airportDetails.filter((airport) => {
      const valueLowerCase = value.toLowerCase();
      const nameLowerCase = airport.airportName.toLowerCase();
      const codeLowerCase = airport.airportCode.toLowerCase();
      const cityLowerCase = airport.cityName.toLowerCase();
      const countryLowerCase = airport.countryName.toLowerCase();

      return (
        nameLowerCase.includes(valueLowerCase) ||
        codeLowerCase.includes(valueLowerCase) ||
        cityLowerCase.includes(valueLowerCase) ||
        countryLowerCase.includes(valueLowerCase)
      );
    });

    setDropdownData((prevState) => ({ ...prevState, [type]: filteredList }));
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleDepartureCityChange = (index, value) => {
    const updatedAirports = [...departureAirports];
    updatedAirports[index] = { name: value, code: "" };
    setDepartureAirports(updatedAirports);
    debouncedFetchFromData(value);

    // Sync with arrival city if index is 0
    if (index === 0) {
      const updatedArrivals = [...arrivalAirports];
      updatedArrivals[1] = { name: value, code: "" };
      setArrivalAirports(updatedArrivals);
    }
  };

  const handleArrivalCityChange = (index, value) => {
    const updatedAirports = [...arrivalAirports];
    updatedAirports[index] = { name: value, code: "" };
    setArrivalAirports(updatedAirports);
    debouncedFetchToData(value);

    // Sync with departure city if index is 0
    if (index === 0) {
      const updatedDepartures = [...departureAirports];
      updatedDepartures[1] = { name: value, code: "" };
      setDepartureAirports(updatedDepartures);
    }
  };

  const handleDepartureDateChange = (index, value) => {
    const today = new Date().toISOString().split("T")[0];
    if (value >= today) {
      const updatedDates = [...departureDates];
      updatedDates[index] = value;
      setDepartureDates(updatedDates);
    }
  };

  const handleFromSelect = (index, airport) => {
    const updatedAirports = [...departureAirports];
    updatedAirports[index] = {
      name: airport.airportName,
      code: airport.airportCode,
    };
    setDepartureAirports(updatedAirports);

    if (index === 0) {
      const updatedArrivals = [...arrivalAirports];
      updatedArrivals[1] = {
        name: airport.airportName,
        code: airport.airportCode,
      };
      setArrivalAirports(updatedArrivals);
    }

    setFocus((prevState) => ({ ...prevState, from: null }));
  };

  const handleToSelect = (index, airport) => {
    const updatedAirports = [...arrivalAirports];
    updatedAirports[index] = {
      name: airport.airportName,
      code: airport.airportCode,
    };
    setArrivalAirports(updatedAirports);

    if (index === 0) {
      const updatedDepartures = [...departureAirports];
      updatedDepartures[1] = {
        name: airport.airportName,
        code: airport.airportCode,
      };
      setDepartureAirports(updatedDepartures);
    }

    setFocus((prevState) => ({ ...prevState, to: null }));
  };

  const handleToggleFocus = (type, index) => {
    if (focus[type] === index) {
      setFocus({ from: null, to: null });
    } else {
      setFocus({
        from: type === "from" ? index : null,
        to: type === "to" ? index : null,
      });
      setDropdownData((prevState) => ({
        ...prevState,
        [type]: customIndexedAirports,
      }));
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setFocus({ from: null, to: null });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isDisabled, setIsDisabled] = useState(false);

  const departureAirportsArray = departureAirports.map((air) => air.code);
  const arrivalAirportsArray = arrivalAirports.map((air) => air.code);

  const handleSearchFlight = async (e) => {
    e.preventDefault();
    dispatch(reset());
    if (departureAirports[0].code === arrivalAirports[0].code) {
      return toast.error("Please select different locations.");
    }

    let query = `/searchResult?origin=${departureAirportsArray}&destination=${arrivalAirportsArray}&startDate=${departureDates}`;

    if (travelerCounts.adults > 1) {
      query += `&adults=${travelerCounts.adults}`;
    }
    if (travelerCounts.children > 0) {
      query += `&children=${travelerCounts.children}`;
    }
    if (travelerCounts.infants > 0) {
      query += `&infants=${travelerCounts.infants}`;
    }
    if (travelerCounts.kids > 0) {
      query += `&kids=${travelerCounts.kids}`;
    }

    if (
      departureAirports.some((airport) => airport.name === "") ||
      arrivalAirports.some((airport) => airport.name === "") ||
      departureDates.some((date) => date === "")
    ) {
      toast.error("Please fill in all fields.");
    } else {
      setIsDisabled(true);

      // To save search history to database
      try {
        const formData = {
          agentId: agentData._id,
          departure: departureAirportsArray,
          arrival: arrivalAirportsArray,
          date: departureDates,
        };

        // return console.log(formData);
        const { data } = await axiosURL.post("/searchHistory", formData);
        const result = data.data;
        localStorage.setItem("search", result?._id);
      } catch (error) {
        console.error(error);
      }
      router.push(query);
    }
  }; 
  return (
    <div className="py-1 text-black">
      <div
        className={`${fromRatingPage ? "" : "rounded"} py-1 hidden md:block`}
      >
        {inputGroups.map((group, index) => (
          <form
            onSubmit={handleSearchFlight}
            key={group.id}
            className={`${
              fromRatingPage ? "grid" : "flex lg:flex-row md:flex-col flex-col"
            } gap-3 mb-4 relative gap-x-1`}
          >
            <div className="flex-1 relative mb-4 sm:mb-0">
              <input
                type="text"
                required
                spellCheck={false}
                className="mr-2 w-full p-2 border border-gray-300 text-sm rounded focus:outline-none focus:ring-[1.5px] focus:ring-green-600  h-[50px]"
                placeholder={`Departure`}
                value={departureAirports[index].name || ""}
                onFocus={handleFocus}
                onChange={(e) =>
                  handleDepartureCityChange(index, e.target.value)
                }
                onClick={() => handleToggleFocus("from", index)}
              />
              {focus.from === index && dropdownData.from.length > 0 && (
                <div ref={dropdownRef} className="absolute top-full">
                  <div className="relative">
                    <ul className="min-w-max mt-3 bg-white shadow-md overflow-hidden rounded-[4px] relative left-0 z-10 ">
                      {dropdownData.from.slice(0, 11).map((airport) => (
                        <div
                          key={airport.airportCode}
                          className="cursor-default"
                          onMouseDown={() => handleFromSelect(index, airport)}
                        >
                          <div className="bg-white py-1 px-2 pr-4 border-b overflow-hidden hover:bg-gray-100 duration-100">
                            <div className="flex justify-between">
                              <div className="flex gap-2 items-center">
                                <MdOutlineLocalAirport className="text-gray-400" />
                                <p className="text-sm text-left font- text-black py-2">
                                  {airport.airportName}, {airport.cityName}{" "}
                                  <span className="text-gray-500 ml-1">
                                    {airport.airportCode}
                                  </span>
                                </p>
                              </div>
                              {/* Display the custom index */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </ul>
                    <FaCaretUp
                      size={20}
                      className="absolute -top-[12px] left-3 text-white"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex-1 relative mb-4 sm:mb-0">
              <input
                type="text"
                required
                spellCheck={false}
                className="mr-2 w-full p-2 border border-gray-300 rounded text-sm focus:outline-none focus:ring-[1.5px] focus:ring-green-600 h-[50px]"
                placeholder={`Arrival`}
                value={arrivalAirports[index].name || ""}
                onFocus={handleFocus}
                onChange={(e) => handleArrivalCityChange(index, e.target.value)}
                onClick={() => handleToggleFocus("to", index)}
              />
              {focus.to === index && dropdownData.to.length > 0 && (
                <div ref={dropdownRef} className="absolute top-full">
                  <div className="relative">
                    <ul className="min-w-max mt-3 bg-white shadow-md overflow-hidden rounded-[4px] relative left-0 z-10 ">
                      {dropdownData.to.slice(0, 11).map((airport) => (
                        <div
                          key={airport.airportCode}
                          className="cursor-default"
                          onMouseDown={() => handleToSelect(index, airport)}
                        >
                          <div className="bg-white py-1 px-2 pr-4 border-b overflow-hidden hover:bg-gray-100 duration-100">
                            <div className="flex justify-between">
                              <div className="flex gap-2 items-center">
                                <MdOutlineLocalAirport className="text-gray-400" />
                                <p className="text-sm text-left font- text-black py-2">
                                  {airport.airportName}, {airport.cityName}{" "}
                                  <span className="text-gray-500 ml-1">
                                    {airport.airportCode}
                                  </span>
                                </p>
                              </div>
                              {/* Display the custom index */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </ul>
                    <FaCaretUp
                      size={20}
                      className="absolute -top-[12px] left-3 text-white"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex-1 relative mb-4 sm:mb-0">
              <input
                type="date"
                required
                min={new Date().toISOString().split("T")[0]}
                className="date-input mr-2 w-full p-2 !text-sm border border-gray-300 rounded focus:outline-none focus:ring-[1.5px] focus:ring-green-600 h-[50px]"
                placeholder={`Departure date for group ${group.id}`}
                value={departureDates[0] || ""}
                onChange={(e) => handleDepartureDateChange(0, e.target.value)}
                onClick={(e) => e.target.showPicker()}
              />
            </div>
            <div className="flex-1 relative mb-4 sm:mb-0">
              <input
                type="date"
                required
                min={
                  departureDates[0] || new Date().toISOString().split("T")[0]
                } // Disable past dates
                className="date-input mr-2 w-full p-2 !text-sm border border-gray-300 rounded focus:outline-none focus:ring-[1.5px] focus:ring-green-600 h-[50px]"
                placeholder={`Departure date for group ${group.id}`}
                value={departureDates[1] || ""}
                onChange={(e) => handleDepartureDateChange(1, e.target.value)}
                onClick={(e) => e.target.showPicker()}
              />
            </div>
            <button
              disabled={isDisabled}
              className="bg-[#00703E] hover:bg-[#185f3f] text-[15px] duration-300 disabled:bg-gray-500 w-[150px] h-[50px] text-white rounded"
            >
              Search Flights
            </button>
          </form>
        ))}
      </div>

      <div className="md:hidden">
        <div className={`${fromRatingPage ? "" : "rounded"}`}>
          {inputGroups.map((group, index) => (
            <form
              onSubmit={handleSearchFlight}
              key={group.id}
              className={`${
                fromRatingPage
                  ? "grid"
                  : "flex lg:flex-row md:flex-col flex-col"
              } gap-3 relative gap-x-1`}
            >
              <div className="flex gap-2">
                <div className="flex-1 relative">
                  <input
                    type="text"
                    required
                    spellCheck={false}
                    className={`w-full p-2 h-[40px] border text-sm rounded focus:outline-none focus:ring-1 focus:ring-green-600`}
                    placeholder={`Departure`}
                    value={departureAirports[index].name || ""}
                    onFocus={handleFocus}
                    onChange={(e) =>
                      handleDepartureCityChange(index, e.target.value)
                    }
                    onClick={() => handleToggleFocus("from", index)}
                  />
                  {focus.from === index && dropdownData.from.length > 0 && (
                    <div ref={dropdownRef} className="absolute top-full">
                      <div className="relative">
                        <ul className="min-w-max mt-3 bg-white shadow-md border overflow-hidden rounded-[4px] relative left-0 z-10 ">
                          {dropdownData.from.map((airport) => (
                            <div
                              key={airport.airportCode}
                              className="bg-blue-50 cursor-pointer hover:bg-blue-100"
                              onMouseDown={() =>
                                handleFromSelect(index, airport)
                              }
                            >
                              <div className="bg-white py-1 px-2 pr-3 border-b overflow-hidden hover:bg-gray-100 duration-100">
                                <div className="flex justify-between">
                                  <div className="flex gap-2 items-center w-full">
                                    <MdOutlineLocalAirport className="text-gray-400" />
                                    <div className="flex text-xs  justify-between items-center w-full">
                                      <p className="text-left text-black py-2">
                                        {airport.airportName.length > 13
                                          ? airport.airportName.slice(0, 14) +
                                            "..."
                                          : airport.airportName}
                                        , {airport.cityName}{" "}
                                      </p>
                                      <span className="text-gray-500 ml-3">
                                        {airport.airportCode}
                                      </span>
                                    </div>
                                  </div>
                                  {/* Display the custom index */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </ul>
                        <FaCaretUp
                          size={21}
                          className="absolute -top-[13px] left-3 text-gray-300 drop-shadow-xl"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex-1 relative">
                  <input
                    type="text"
                    required
                    className="w-full p-2 h-[40px] border text-sm rounded focus:outline-none focus:ring-1 focus:ring-green-600"
                    placeholder={`Arrival`}
                    value={arrivalAirports[index].name || ""}
                    onFocus={handleFocus}
                    spellCheck={false}
                    onChange={(e) =>
                      handleArrivalCityChange(index, e.target.value)
                    }
                    onClick={() => handleToggleFocus("to", index)}
                  />
                  {focus.to === index && dropdownData.to.length > 0 && (
                    <div
                      ref={dropdownRef}
                      className="absolute top-full right-0"
                    >
                      <div className="relative">
                        <ul className="min-w-max mt-3 bg-white shadow-md border overflow-hidden rounded-[4px] relative left-0 z-10">
                          {dropdownData.to.map((airport) => (
                            <div
                              key={airport.airportCode}
                              className="bg-blue-50 cursor-pointer hover:bg-blue-100"
                              onMouseDown={() => handleToSelect(index, airport)}
                            >
                              <div className="bg-white py-1 px-2 pr-3 border-b overflow-hidden hover:bg-gray-100 duration-100">
                                <div className="flex justify-between">
                                  <div className="flex gap-2 items-center w-full">
                                    <MdOutlineLocalAirport className="text-gray-400" />
                                    <div className="flex text-xs  justify-between items-center w-full">
                                      <p className="text-left text-black py-2">
                                        {airport.airportName.length > 13
                                          ? airport.airportName.slice(0, 14) +
                                            "..."
                                          : airport.airportName}
                                        , {airport.cityName}{" "}
                                      </p>
                                      <span className="text-gray-500 ml-3">
                                        {airport.airportCode}
                                      </span>
                                    </div>
                                  </div>
                                  {/* Display the custom index */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </ul>
                        <FaCaretUp
                          size={21}
                          className="absolute -top-[13px] right-3 text-gray-300 drop-shadow-xl"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex gap-2">
                <div className="flex-1 relative">
                  <input
                    type="date"
                    required
                    className="date-input !text-sm w-full p-2 h-[40px] border  rounded focus:outline-none focus:ring-1 focus:ring-green-600"
                    placeholder={`Departure date for group ${group.id}`}
                    value={departureDates[0] || ""}
                    onChange={(e) =>
                      handleDepartureDateChange(0, e.target.value)
                    }
                    onClick={(e) => e.target.showPicker()}
                  />
                </div>
                <div className="flex-1 relative">
                  <input
                    type="date"
                    required
                    min={new Date().toISOString().split("T")[0]} // Disable past dates
                    className="w-full date-input !text-sm p-2 h-[40px] border rounded focus:outline-none focus:ring-1 focus:ring-green-600"
                    placeholder={`Departure date for group ${group.id}`}
                    value={departureDates[1] || ""}
                    onChange={(e) =>
                      handleDepartureDateChange(1, e.target.value)
                    }
                    onClick={(e) => e.target.showPicker()}
                  />
                </div>
              </div>
              <button
                disabled={isDisabled}
                className="bg-green-800 disabled:bg-gray-500 w-full md:w-[150px] h-[40px] text-sm  text-white rounded mt-2"
              >
                Search Flights
              </button>
            </form>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Page;
